<template>
    <div :class="['btn', 'btn-circle', buttonTypeClass]">
        <slot />
    </div>
</template>


<script setup>
const props = defineProps({
    type: {
        type: String,
        default: 'default',
        validator: (value) => {
            // Acceptable types can be 'delete', 'save', etc.
            return ['delete', 'save', 'default'].includes(value);
        }
    }
});
const buttonTypeClass = computed(() => {
    return {
        'delete': props.type === 'delete',
        'save': props.type === 'save',
        // Add more types as needed
    };
});
</script>



<style scoped>

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 10px;
  border-radius: 50%;
  margin: auto;
  text-align: center;
  font-size: 12px;
  background-color: #D5161B; /* Red background for delete */
  color: white;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for color changes */
}

/* Specific styles for delete type */
.btn-circle.delete {
    background-color: white; /* Default background color */
    color: rgba(83, 81, 81, 0.993); /* Default icon color */
}

/* Dark mode styles */
.dark-mode .btn-circle {
  background-color: rgba(255, 255, 255, 0.1) !important; /* Dark mode background */
  color: white !important; /* Dark mode icon color */
}

.dark-mode .btn-circle.delete {
  background-color: rgba(255, 0, 0, 0.7) !important; /* Dark mode red for delete */
}
.btn-circle i {
    font-style: normal; /* Ensure Font Awesome icons are not italic */
}
</style>